<template>
  <div>{{eventValue}}</div>
</template>
<script>
import { ethers } from 'ethers'
export default {
  props: ['value', 'type'],
  data() {
    return {
      eventValue: ''
    }
  },
  watch: {
    type(val) {
      console.log(val, this.type)
      this.getEventValue()
    }
  },
  mounted() {
    console.log(this.eventValue, this.type)
    this.getEventValue()
  },
  methods: {
    getEventValue() {
      let eventValue = this.eventValue
      if (this.type) {
        eventValue = ethers.utils.formatUnits(this.value, "wei")
      } else {
        eventValue = this.value
      }
      this.eventValue = eventValue
    }
  },
}
</script>
<style scoped>
div {
  font-size: 12px;
}
</style>