<template>
  <div class="home" v-loading="loading">
    <PageNav
      @delSol="delSol"
    />
    <el-container>
      <el-aside width="200px">
        <SideMenu @handle="refreshPage" @editSuccess="refreshPage" ref="sideMenu" />
      </el-aside>
      <el-container style="padding: 24px;display:block">
        <el-tabs class="tabs" v-model="openValue" type="card" closable @tab-remove="removeTab" @tab-click="clickTab">
          <el-tab-pane
            v-for="(item) in openSols"
            :key="item.content.id"
            :label="item.title"
            :name="item.content.id"
          >
            <div class="contract-list">
              <el-descriptions
                :labelStyle="{ width: '80px' }"
                class="margin-top"
                :title="item.title"
                :column="4"
                size="middle"
                border
                style="margin-bottom: 10px"
              >
                <template slot="extra">
                  <el-button type="info" size="small" @click="toEtherscan(item.content)">
                    {{$t('btn_etherscan')}}</el-button>
                  <el-button type="warning" size="small" @click="shareSol(item.content)"
                    >{{$t('btn_share')}}</el-button
                  >
                  <el-button type="danger" size="small" @click="delSol"
                    >{{$t('btn_del')}}</el-button
                  >
                  <el-button type="success"  size="small" @click="editSol"
                    >{{$t('btn_edit')}}</el-button
                  >
                  <el-button type="primary" size="small" @click="changeChain"
                    >{{$t('btn_change')}}</el-button
                  >
                </template>
                <el-descriptions-item>
                  <template slot="label"> <p style="width:100px">{{$t('desc_time')}}</p> </template>
                  {{ item.content.createAt ? formatDate(item.content.createAt) : '--' }}
                </el-descriptions-item>

                <el-descriptions-item>
                  <template slot="label"> {{$t('desc_chain')}} </template>
                  {{ item.content.chain && item.content.chain.chainName || $t('desc_msg') }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> {{$t('desc_balance')}} </template>
                  <div style="cursor: pointer">
                    {{ item.content.balanceInEth }}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"><p style="width:120px">{{$t('desc_address')}}</p> </template>
                  <div style="cursor: pointer">
                    {{ item.content.address }} <i class="el-icon-copy-document" style="cursor: pointer;margin-left:10px" @click="copy(item.content.address, 'address')"></i>
                    <svg style="margin-left:10px" @click="toEtherscanAddress(item.content.address, item.content.chain)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item >
                  <template slot="label"> {{$t('desc_abi')}} </template>
                  <div @click="copy(item.content.abi, 'abi')" style="cursor: pointer">
                    {{$t('desc_copy')}} <i class="el-icon-copy-document" style="cursor: pointer;margin-left:10px" ></i>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> {{$t('desc_remark')}} </template>
                  {{item.content.remark || '--'}}
                </el-descriptions-item>
              </el-descriptions>
              <sol-body 
                :address="item.content.address" 
                :result="item.result || []" 
                :abi="item.content.abi" 
                :chain="item.content.chain" 
                :sol="item.content"
                @updateSol="updateSol"
                @resulted="resulted" 
                @changeChain="changeChain"></sol-body>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-container>
    </el-container>
    <ShareSol v-if="isShowShareSol" :sol="shareSolData" @cancel="cancelShare" :type="shareSolType" @getContract="getContract" @shareSuccess="shareSuccess" />
  </div>
</template>

<script>
import NewContract from "@/components/newContract.vue";
import PageNav from "@/components/nav.vue";
import SolBody from "../components/SolBody.vue";
import SideMenu from "../components/SideMenu.vue";
import { getLs, setLs } from "../service/service";
import { walletSwitchChain } from '../lib/walletSwitchChain'
import { formatDate } from '../utils/utils'
import { checkContractInfo, getContract } from '../http/api'
import { ethers } from 'ethers'
import ShareSol from '../components/ShareSol.vue'
export default {
  name: "Home",
  components: {
    SolBody,
    NewContract,
    PageNav,
    SideMenu,
    ShareSol
  },
  computed: {
    web3() {
      return this.$store.state.web3
    },
    address() {
      let address = this.$store.state.address
      return address
    },
    network() {
      let network = this.$store.state.network
      return network
    },
    activeId() {
      let activeId = this.$store.state.activeId
      return activeId
    },
    openSols() {
      let openSols = this.$store.state.openSols
      let data = openSols
      data.forEach(async (e, index) => {
        e.content.balanceInEth = await this.getBalance(e.content)
        openSols[index] = e
      })
      // openSols = data
      return openSols
    },
    menuList() {
      let menuList = this.$store.state.menuList
      return menuList
    },
    contractList() {
      let contractList = this.$store.state.contractList
      return contractList
    },
  },
  data() {
    return {
      openValue: this.activeId,
      db: [],
      loading: false,
      now_address_id: "0",
      sol: {},
      tabIndex: 0,
      isShowShareSol: false,
      shareSolData: {},
      shareSolType: ''
    };
  },
  filters: {
    slim_address(addr = '') {
      return `${addr.slice(0, 4)}......${addr.slice(-4)}`;
    },
  },
  watch: {
    activeId(val) {
      console.log(val)
      this.openValue = val
      // this.$store.commit("setMenuList", this.activeId)
    },
    openSols: {
      handler(val) {
        console.log(val)
      },
      deep: true
    }
  },
  mounted() {
    let token = this.$route.params.token
    if (token) {
      this.checkContractInfo(token)
    }
    this.refreshPage()
  },
  methods: {
    toEtherscanAddress(address, chain) {
      let network = chain
      console.log(network)
      let name = ''
      if (network.chainName.toLowerCase() == 'ropsten') {
        name = 'ropsten.'
      } else if (network.chainName.toLowerCase() == 'kovan') {
        name = 'kovan.'
      } else if (network.chainName.toLowerCase() == 'rinkeby') {
        name = 'rinkeby.'
      } else if (network.chainId == 5) {
        name = 'goerli.'
      } else if (network.chainName.toLowerCase() == 'sepolia') {
        name = 'sepolia.'
      }
      let url = `https://${name}etherscan.io/address/${address}`
      window.open(url)
    },
    toEtherscan(content) {
      console.log(content)
      let address = content.address
      let network = content.chain
      let name = ''
      if (network.chainName.toLowerCase() == 'ropsten') {
        name = 'ropsten.'
      } else if (network.chainName.toLowerCase() == 'kovan') {
        name = 'kovan.'
      } else if (network.chainName.toLowerCase() == 'rinkeby') {
        name = 'rinkeby.'
      } else if (network.chainName.toLowerCase() == 'goerli') {
        name = 'goerli.'
      } else if (network.chainName.toLowerCase() == 'sepolia') {
        name = 'sepolia.'
      }
      let url = `https://${name}etherscan.io/address/${address}`
      window.open(url)
    },
    checkContractInfo(token) {
      checkContractInfo({token}).then(res => {
        console.log(res)
        if (res.code == 0) {
          if (res.openSourceType == 'Limited') {
            this.isShowShareSol = true
            this.shareSolType = 'get'
          } else {
            this.getContract()
          }
        }
      })
    },
    getContract(password='') {
      let token = this.$route.params.token
      getContract({token, password}).then(res => {
        console.log(res)
        this.isShowShareSol = false
        this.shareSolType = ''
        let contract = res.contract.contract_info
        let sol = {
          isImport: true,
          name: contract.name,
          address: contract.contract_address,
          abi: contract.contract_abi,
          chain: contract.chain_info,
          remark: contract.description
        }
        console.log(sol)
        this.$refs.sideMenu.refresh(sol)
        this.$router.replace({ path: '/' })
      })
    },
    shareSuccess(token) {
      this.shareSolData.token = token
      this.updateSol(this.shareSolData)
    },
    cancelShare() {
      this.isShowShareSol = false
      this.shareSolType = ''
      this.shareSolData = {}
    },
    shareSol(shareSolData) {
      this.isShowShareSol = true
      this.shareSolData = shareSolData
    },
    async getBalance(content) {
      if (this.web3 && content) {
        let balance = await this.web3.getBalance(content.address)
        let balanceInEth = await ethers.utils.formatEther(balance)
        return balanceInEth
      } else {
        return 0
      }
    },
    resulted(result) {
      console.log(result)
      let openSols = this.openSols
      openSols.forEach(e => {
        if (e.content.id == this.activeId) {
          if (!e.result) e.result = [] 
          if (e.result.some(e => e.content.hash == result.content.hash) && result.content.hash) {
            e.result.forEach((el, index) => {
              if (el.content.hash == result.content.hash) {
                e.result[index] = result
              }
            })
          } else {
            e.result.unshift(result)
          }
        }
      })
      // console.log(openSols)
      // openSols = JSON.parse(JSON.stringify(openSols))
      setLs('openSols', JSON.parse(JSON.stringify(openSols))).then(res => {
        this.$store.commit("setOpenSols", res)
      })
    },
    updateSol(sol) {
      let openSols = this.openSols
      let contractList = this.contractList
      let menuList = this.menuList
      openSols.forEach(e => {
        if (e.content.id == this.activeId) {
          e.content = sol
        }
      })
      contractList.forEach((e, index) => {
        if (e.id == this.activeId) {
          contractList[index] = sol
        }
      })
      for (let i = 0; i < menuList.length; i++) {
        let son = menuList[i].son
        son.forEach((e, index) => {
          if (e.id == this.activeId) {
            son[index] = sol
          }
        })
        menuList[i].son = son
      }
      setLs('openSols', JSON.parse(JSON.stringify(openSols))).then(res => {
        console.log(res)
        this.$store.commit("setOpenSols", res)
      })
      setLs('contractList', JSON.parse(JSON.stringify(contractList))).then(res => {
        console.log(res)
        this.$store.commit("setContractList", res)
      })
      setLs('menuList', JSON.parse(JSON.stringify(menuList))).then(res => {
        console.log(res)
        this.$store.commit("setMenuList", res)
      })
    },
    formatDate(time) {
      return formatDate('YYYY-mm-dd', time)
    },
    
    clickTab() {
      this.$store.commit("setActiveId", this.openValue)
      setLs('activeId', [this.openValue]).then(res => {
        console.log(res)
      })
    },
    addTab(sol) {
      console.log(sol)
      let openSols = this.openSols
      if (openSols.some(e => e.content.id == sol.id)) return
      openSols.push({
        title: sol.name,
        name: sol.id,
        content: sol
      });
      let activeId = sol.id
      this.$store.commit("setActiveId", sol.id)
      this.$store.commit("setOpenSols",openSols)
      setLs('openSols', openSols).then(res => {
        console.log(res)
      })
      setLs('activeId', [activeId]).then(res => {
        console.log(res)
      })
    },
    removeTab(targetName) {
      let openSols = this.openSols || []
      let activeName = this.activeId;
      if (activeName === targetName) {
        openSols.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = openSols[index + 1] || openSols[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      openSols = openSols.filter(e => e.content.id !== targetName)
      this.$store.commit("setActiveId", activeName)
      this.$store.commit("setOpenSols", openSols)
      setLs('openSols', openSols).then(res => {
        console.log(res)
      })
      setLs('activeId', [activeName]).then(res => {
        console.log(res)
      })
    },
    changeChain() {
      let sol = this.openSols.filter(e => e.content.id == this.activeId)
      if (sol && sol.length) sol = sol[0].content
      if (sol.chain.chainId != web3.currentProvider.chainId) {
        walletSwitchChain(sol.chain.chainId).then(() => {
          this.$store.commit("getWeb3")
          this.$notify.success({
            title: this.$t('notify_status_success'),
            message: this.$t('notify_changechain_success_msg', {chain: sol.chain.chainName}),
          })
        }).catch(err => {
          this.$notify.error({
            title: this.$t('notify_status_error'),
            message: err,
          })
        })
      } else {
        this.$notify({
          title: this.$t('notify_status_info'),
          message: this.$t('notify_changechain_info_msg'),
        });
      }
    },
    copy(value, type) {
      if (type == 'address') {
       value = value.replace(/\"/g, "")
      } else if (type == 'abi') {
        value = value.map(e => {
          delete e.otherName
          return e
        })
        value = JSON.stringify(value)
      } else {
        value = JSON.stringify(value)
      }
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value)
        this.$notify.success({
          title: this.$t('copy_status_success'),
          message: this.$t('copy_success_msg'),
        });
      }
    },

    select_contract({ index }) {
      setLs("now_address_id", index).then((res) => {
        console.log(res);
      });
    },

    editSol() {
      let sol = this.openSols.filter(e => e.content.id == this.activeId)
      if (sol && sol.length) sol = sol[0].content
      if (sol && sol.address) {
        this.$refs.sideMenu.editSol(sol)
      } else {
        this.$notify.error({
          title: this.$t('notify_status_error'),
          message: this.$t('notify_edit_error_msg'),
        });
      }
    },

    delSol() {
      let sol = this.openSols.filter(e => e.content.id == this.activeId)
      if (sol && sol.length) sol = sol[0].content
      if (sol && sol.address) {
        this.$refs.sideMenu.delSol(sol)
      } else {
        this.$notify.error({
          title: this.$t('notify_status_error'),
          message: this.$t('notify_edit_error_msg'),
        });
      }
    },

    refreshPage(value) {
      if (value) {
        this.addTab(value)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  min-height: 100vh;
}
.tabs {
  width: 100%;
}
</style>
<style lang="scss">
.home {
  .el-tabs--card>.el-tabs__header .el-tabs__nav {
    height: 34px;
    line-height: 34px;
  }
  .el-tabs__item {
    height: 34px;
    line-height: 34px;
    font-weight: 400;
  }
  .el-tabs__item.is-active {
    color: #3997EE;
  }
  .contract-list {
    .el-descriptions__header {
      margin-bottom: 10px;
    }
  }
}
</style>
