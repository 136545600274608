<template>
  <div @keydown.enter.prevent="submit_model">
    <el-dialog :title="$t('add_collection_title')" :visible.sync="show_dialog">
      <el-form :model="form" ref="form">
        <el-form-item :label="$t('add_collection_title')" prop="name" label-width="100px"
        :rules="[
          { required: true, message: $t('add_collection_form_name_msg'), trigger: 'blur' },
        ]">
          <el-input v-model="form.name" autocomplete="off" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show_dialog = false">{{$t('cancel')}}</el-button>
        <el-button type="primary" @click="submit_model">{{$t('ok')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      form: {
        name: ""
      },
    }
  },
  methods: {
    open() {
      this.show_dialog = true;
    },
    submit_model() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let formData = this.form;
          formData.son = []
          this.$emit('add', formData)
          this.form = {
            name: ''
          };
          this.show_dialog = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>

</style>