<template>
  <div class="about">
    <h1>This is an about page</h1>
    <el-button type="primary"  @click="hello">hello</el-button>
  </div>
</template>
<script>
import { hello } from '../http/api'

export default {
  methods: {
    hello() {
      hello().then(res => {
        console.log(res)
      })
    }
  },
}
</script>
