<template>
  <div class="sol-body">
    <el-menu
      class="el-menu-vertical"
      :default-openeds="['1', '2']"
      text-color="#606266"
      active-text-color="#3997EE"
    >
      <el-submenu index="1">
        <template slot="title">
          <span style="display: flex; align-items: center"
            ><span style="color: rgb(22, 155, 93); margin-right: 0.3em"
              >Read</span
            >{{ $t("sol_body_read") }}</span
          >
        </template>
        <el-menu-item
          v-for="(item, index) in abiList.readAbi"
          :key="index"
          :index="'1-' + index"
          @click="selectMenu(item)"
        >
          <span slot="title">
            <p class="fun-other-name">{{ item.otherName || item.name }}</p>
            <p class="fun-name" v-if="item.otherName">{{ item.name }}</p>
          </span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="2" style="margin-top: 12px">
        <template slot="title">
          <span style="display: flex; align-items: center"
            ><span style="color: rgb(57, 151, 238); margin-right: 0.3em"
              >Write</span
            >{{ $t("sol_body_write") }}</span
          >
        </template>
        <el-menu-item
          v-for="(item, index) in abiList.writeAbi"
          :key="index"
          :index="'2-' + index"
          @click="selectMenu(item)"
        >
          <p class="fun-other-name">{{ item.otherName || item.name }}</p>
          <p class="fun-name" v-if="item.otherName">{{ item.name }}</p>
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="right">
      <div v-if="(fun && fun.name) || (result && result.length)">
        <div
          v-if="fun && fun.name"
          class="right-section-wrap"
          style="margin-bottom: 10px"
          v-loading="loading"
        >
          <div class="fun-title">
            {{ fun.name }} <i class="el-icon-close" @click="delFun"></i>
          </div>
          <div class="right-section" @click="showFunSet = !showFunSet">
            <span
              class="section-title"
              @click.stop
              :style="{ top: showFunSet ? '-.5em' : '.9em' }"
              >{{ $t("sol_body_set_remark") }}
              <span v-show="!showFunSet"
                >/{{ $t("sol_body_set_remark_item") }}</span
              ></span
            >
            <div
              class="section-wrap"
              @click.stop
              :style="{
                height: showFunSet ? '66px' : '0',
                opacity: showFunSet ? 1 : 0,
              }"
            >
              <div class="section-form" style="width: 55%">
                <div class="form-label">
                  {{ $t("sol_body_set_remark_item") }}
                </div>
                <div class="form-input">
                  <el-input
                    v-model="otherName"
                    :placeholder="$t('sol_body_set_remark_item')"
                  ></el-input>
                  <el-button
                    type="primary"
                    size="small"
                    class="section-btn"
                    @click="saveOtherName"
                    >{{ $t("sol_body_set_remark_save") }}</el-button
                  >
                </div>
              </div>
            </div>
            <div class="show-arrow" @click.stop="showFunSet = !showFunSet">
              <i
                class="el-icon-arrow-down"
                :style="{ transform: showFunSet ? 'rotate(180deg)' : '' }"
              ></i>
            </div>
          </div>
          <div v-if="fun.inputs && fun.inputs.length" class="right-section">
            <span class="section-title">{{
              $t("sol_body_section_title_1")
            }}</span>
            <div>
              <div
                class="section-form"
                v-for="(input, index) in fun.inputs"
                :key="index"
              >
                <div class="form-label">
                  <div>
                    {{ input.name }}
                    <span
                      >{{ $t("sol_body_section_type") }}：{{ input.type }}</span
                    >
                  </div>
                  <div v-if="isUint(input.type)">
                    <el-popover
                      placement="top"
                      width="220"
                      v-model="visible"
                      style="margin-right: 20px"
                    >
                      <el-date-picker
                        v-model="value1"
                        @change="pickerChange($event, input.name)"
                        type="datetime"
                        :placeholder='$t("sol_body_util_2")'>
                      </el-date-picker>
                      <p class="sort" slot="reference">{{ $t("sol_body_util_3") }}<i class="el-icon-time" style="transform: rotate(0deg);"></i></p>
                    </el-popover>
                    <div
                      class="sort"
                      @click="conversion(input.name)"
                    >
                      {{ $t("sol_body_util_1") }}<i class="el-icon-sort"></i>
                    </div>
                  </div>
                  
                </div>
                <div class="form-input">
                  <el-input
                    v-model="formData[input.name]"
                    autosize
                    :type="isTextarea(input.type) ? 'textarea' : ''"
                    :placeholder="
                      isTextarea(input.type)
                        ? input.type + $t('sol_body_textarea_placeholder')
                        : input.type
                    "
                    style="width: 55%"
                  ></el-input>
                </div>
                <div
                  class="conversion-list"
                  v-if="showConversion(input.type, formData[input.name])"
                >
                  <div
                    class="conversion-item"
                    @click="
                      () => {
                        conversionType = input.name;
                        decimals = 18;
                        toWei();
                      }
                    "
                  >
                    toWei(10^18)
                  </div>
                  <div
                    class="conversion-item"
                    @click="
                      () => {
                        conversionType = input.name;
                        decimals = 9;
                        toWei();
                      }
                    "
                  >
                    toGwei(10^9)
                  </div>
                  <div class="conversion-hint">
                    invalid number, please use digital conversion
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="right-section"
            v-if="fun.stateMutability != 'view'"
            @click="showSendSet = !showSendSet"
          >
            <span
              class="section-title"
              @click.stop
              :style="{ top: showSendSet ? '-.5em' : '.9em' }"
              >{{ $t("sol_body_other_value") }}
              <span v-show="!showSendSet">/value/gasPrice</span></span
            >
            <div
              class="section-send-info"
              :style="{
                height: showSendSet ? 'auto' : '0',
                opacity: showSendSet ? 1 : 0,
              }"
              @click.stop
            >
              <div class="section-form">
                <div class="form-label">
                  <div>
                    Value <span>{{ $t("sol_body_other_value_msg") }}</span>
                  </div>
                  <div class="sort" @click="conversion('payableValue')">
                    {{ $t("sol_body_util_1") }}<i class="el-icon-sort"></i>
                  </div>
                </div>
                <div class="form-input">
                  <el-input
                    v-model="formData['payableValue']"
                    autosize
                    placeholder="0"
                    style="width: 55%"
                  ></el-input>
                </div>
                <div
                  class="conversion-list"
                  v-if="showConversion('uint', formData['payableValue'])"
                >
                  <div
                    class="conversion-item"
                    @click="
                      () => {
                        conversionType = 'payableValue';
                        decimals = 18;
                        toWei();
                      }
                    "
                  >
                    toWei(10^18)
                  </div>
                  <div
                    class="conversion-item"
                    @click="
                      () => {
                        conversionType = 'payableValue';
                        decimals = 9;
                        toWei();
                      }
                    "
                  >
                    toGwei(10^9)
                  </div>
                  <div class="conversion-hint">
                    invalid number, please use digital conversion
                  </div>
                </div>
              </div>
              <div class="section-form">
                <div class="form-label">
                  <div>gasPrice <span></span></div>
                  <div class="sort" @click="conversion('gasPrice')">
                    {{ $t("sol_body_util_1") }}<i class="el-icon-sort"></i>
                  </div>
                </div>
                <div class="form-input">
                  <el-input
                    v-model="formData['gasPrice']"
                    autosize
                    placeholder="0"
                    style="width: 55%"
                  ></el-input>
                </div>
                <div
                  class="conversion-list"
                  v-if="showConversion('uint', formData['gasPrice'])"
                >
                  <div
                    class="conversion-item"
                    @click="
                      () => {
                        conversionType = 'gasPrice';
                        decimals = 18;
                        toWei();
                      }
                    "
                  >
                    toWei(10^18)
                  </div>
                  <div
                    class="conversion-item"
                    @click="
                      () => {
                        conversionType = 'gasPrice';
                        decimals = 9;
                        toWei();
                      }
                    "
                  >
                    toGwei(10^9)
                  </div>
                  <div class="conversion-hint">
                    invalid number, please use digital conversion
                  </div>
                </div>
              </div>
            </div>
            <div class="show-arrow" @click.stop="showSendSet = !showSendSet">
              <i
                class="el-icon-arrow-down"
                :style="{ transform: showSendSet ? 'rotate(180deg)' : '' }"
              ></i>
            </div>
          </div>
          <el-button
            type="primary"
            size="small"
            style="padding: 8px 25px; margin-top: 20px"
            @click="apply"
            >{{ $t("apply") }}</el-button
          >
        </div>
        <div
          class="result right-section-wrap"
          v-for="(item, index) in result"
          :key="index"
        >
          <div class="result-hd">
            <div class="result-title">
              {{ $t("sol_body_result_title") }}: {{ item.name }}
              <i class="el-icon-loading" v-if="item.content && item.content.hash && !item.content.blockNumber"></i>
            </div>
            <div style="display: flex">
              <div class="result-hash" v-if="item.content && item.content.hash">
                {{ $t("sol_body_result_hash") }}:
                {{ item.content.hash || item.content.transactionHash }}
                <svg
                  @click="copy(item.content.transactionHash, 'address')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                  <path
                    d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
                  ></path>
                </svg>
                <svg
                  @click="toEtherscan(item.content.transactionHash)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"
                  ></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg>
              </div>
              <svg
                t="1662480025202"
                @click="replay(item)"
                style="margin-left: 8px"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2378"
                width="16"
                height="16"
              >
                <path
                  d="M960 416V192l-73.056 73.056a447.712 447.712 0 0 0-373.6-201.088C265.92 63.968 65.312 264.544 65.312 512S265.92 960.032 513.344 960.032a448.064 448.064 0 0 0 415.232-279.488 38.368 38.368 0 1 0-71.136-28.896 371.36 371.36 0 0 1-344.096 231.584C308.32 883.232 142.112 717.024 142.112 512S308.32 140.768 513.344 140.768c132.448 0 251.936 70.08 318.016 179.84L736 416h224z"
                  p-id="2379"
                  fill="#606266"
                ></path>
              </svg>
            </div>
          </div>
          <div
            class="result-msg"
            v-if="item.content.type && item.content.nonce"
          >
            <div class="msg-item">
              <span>Tx Type</span>
              <span>{{ item.content.type == 2 ? "2(EIP-1599)" : "1" }}</span>
            </div>
            <div class="msg-item">
              <span>Nonce</span>
              <span>{{ item.content.nonce }}</span>
            </div>
            <div class="msg-item">
              <span>BlockNumber</span>
              <span>{{ item.content.blockNumber || "--" }}</span>
            </div>
            <!-- <div class="msg-item">
              <span>BlockConfirm</span>
              <span>{{getBlockConfirm(item.content.blockNumber)}}</span>
            </div> -->
          </div>

          <div class="result-time">
            <div class="time-item">
              <i class="el-icon-time" style="margin-right: 6px"></i
              ><span v-if="item.createAt">{{ formatDate(item.createAt) }}</span>
            </div>
            <div class="time-item" v-if="item.confirmed">
              <i class="el-icon-time" style="margin-right: 6px"></i>Confirmed
              within {{ item.confirmed }} sec
            </div>
          </div>
          <div
            v-if="
              item && item.formData && JSON.stringify(item.formData) !== '{}'
            "
          >
            <div class="result-parameter">
              {{ $t("sol_body_result_params") }}
            </div>
            <p class="result-function">{{ item && item.fun }}</p>
            <json-viewer
              style="margin-top: -20px"
              :value="(item && item.formData) || ''"
              :expand-depth="2"
            ></json-viewer>
          </div>
          <div class="result-content" v-if="!item.content.hash">
            <div class="result-text">
              <svg
                v-if="item.state == 'success' || item.state == 'receiptSuccess'"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
                class="icon-success"
              >
                <path
                  d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M34.5548098,16.4485711 C33.9612228,15.8504763 32.9988282,15.8504763 32.4052412,16.4485711 L32.4052412,16.4485711 L21.413757,27.5805811 L21.413757,27.5805811 L21.4034642,27.590855 C21.0097542,27.9781674 20.3766105,27.9729811 19.9892981,27.5792711 L19.9892981,27.5792711 L15.5947588,23.1121428 C15.0011718,22.514048 14.0387772,22.514048 13.4451902,23.1121428 C12.8516033,23.7102376 12.8516033,24.6799409 13.4451902,25.2780357 L13.4451902,25.2780357 L19.6260786,31.5514289 C20.2196656,32.1495237 21.1820602,32.1495237 21.7756472,31.5514289 L21.7756472,31.5514289 L34.5548098,18.614464 C35.1483967,18.0163692 35.1483967,17.0466659 34.5548098,16.4485711 Z"
                ></path>
              </svg>
              <svg
                v-else
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
                class="icon-error"
              >
                <path
                  d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M32.57818,15.42182 C32.0157534,14.8593933 31.1038797,14.8593933 30.541453,15.42182 L30.541453,15.42182 L24.0006789,21.9625941 L17.458547,15.42182 C16.8961203,14.8593933 15.9842466,14.8593933 15.42182,15.42182 C14.8593933,15.9842466 14.8593933,16.8961203 15.42182,17.458547 L15.42182,17.458547 L21.9639519,23.9993211 L15.42182,30.541453 C14.8593933,31.1038797 14.8593933,32.0157534 15.42182,32.57818 C15.9842466,33.1406067 16.8961203,33.1406067 17.458547,32.57818 L17.458547,32.57818 L24.0006789,26.0360481 L30.541453,32.57818 C31.1038797,33.1406067 32.0157534,33.1406067 32.57818,32.57818 C33.1406067,32.0157534 33.1406067,31.1038797 32.57818,30.541453 L32.57818,30.541453 L26.0374059,23.9993211 L32.57818,17.458547 C33.1406067,16.8961203 33.1406067,15.9842466 32.57818,15.42182 Z"
                ></path>
              </svg>
              {{
                item.state == "success"
                  ? $t("sol_body_result_success")
                  : $t("sol_body_result_fail")
              }}
            </div>
            <div v-if="item">
              <p v-if="isBooleanType(item.content)">{{ item.content }}</p>
              <json-viewer
                v-else
                :value="(item && item.content) || ''"
                :expand-depth="1"
              ></json-viewer>
            </div>
          </div>
          <div
            class="result-content"
            v-if="item.content.events && item.content.events.length"
          >
            <div class="result-text">{{ $t("sol_body_result_events") }}</div>
            <div class="event-list">
              <div
                class="event-item"
                v-for="(event, index) in item.content.events"
                :key="index"
              >
                <div
                  class="event-title"
                  @click="
                    () => {
                      event.showEventDetail = !event.showEventDetail;
                      result = JSON.parse(JSON.stringify(result));
                    }
                  "
                >
                  <div>{{ event.eventSignature }}</div>
                  <div class="show-arrow">
                    <i
                      class="el-icon-arrow-down"
                      :style="{
                        transform: event.showEventDetail
                          ? 'rotate(180deg)'
                          : '',
                      }"
                    ></i>
                  </div>
                </div>
                <div v-show="event.showEventDetail">
                  <div
                    class="parameter"
                    v-for="(item, index) in getParameter(event.eventSignature)"
                    :key="index"
                  >
                    <div class="parameter-item">
                      <span>{{ item }}</span>
                      <div
                        v-if="event.args[index].constructor == Array"
                        class="event-value"
                      >
                        <p v-for="ite in event.args[index]" :key="ite">
                          {{ ite }}
                        </p>
                      </div>
                      <div v-else class="event-value">
                        <EventValue
                          v-if="item == 'uint256'"
                          :value="event.args[index]"
                          :type="event.showNum"
                        />
                        <p v-else>{{ event.args[index] }}</p>
                      </div>
                      <i
                        v-if="item == 'uint256'"
                        class="el-icon-sort"
                        style="
                          cursor: pointer;
                          margin-left: 10px;
                          transform: rotate(90deg);
                        "
                        @click="
                          () => {
                            event.showNum = !event.showNum;
                            result = JSON.parse(JSON.stringify(result));
                          }
                        "
                      ></i>
                      <i
                        class="el-icon-copy-document"
                        style="cursor: pointer; margin-left: 10px"
                        @click="copy(event.args[index], item)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty :description="$t('sol_body_result_empty')"></el-empty>
      </div>
    </div>
    <div class="conversion-dialog" v-if="isShowConversion">
      <div class="conversion-content" @click.stop>
        <div class="dialog-hd">
          <div class="dialog-title">{{ $t("sol_body_util_1") }}</div>
          <div class="dialog-close" @click="isShowConversion = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-bd">
          <el-select
            v-model="decimals"
            :placeholder="$t('sol_body_decimals_placeholder')"
            style="width: 100%"
            @change="selectChange"
          >
            <el-option
              v-for="item in conversionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-input
            v-show="isShowDecimalsInput"
            v-model="decimalsInput"
            type="number"
            :placeholder="$t('sol_body_decimals_input_placeholder')"
            style="margin-top: 14px"
          ></el-input>
          <p v-show="isShowDecimalsInput" class="hint">
            {{ $t("sol_body_decimals_input_hint") }}
          </p>
        </div>
        <div class="dialog-ft">
          <div class="btn" @click="toWei">
            {{ $t("sol_body_decimals_btn") }}
          </div>
        </div>
      </div>
    </div>
    <div class="dialog" v-if="isShowDialog">
      <div class="dialog-content">
        <div class="dialog-hd">
          <div class="dialog-title">{{ $t("sol_body_chain_error") }}</div>
          <div class="dialog-close" @click="isShowDialog = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="AccountDetails__CloseColor-sc-75okty-10 loWQUb"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        </div>
        <div class="dialog-bd">
          <p class="dialog-bd-content">
            {{ $t("sol_body_chain_error_content") }}
          </p>
          <el-button
            type="primary"
            class="dialog-bd-btn"
            @click="changeChain"
            >{{
              $t("sol_body_chain_error_btn_1", { name: this.chain.chainName })
            }}</el-button
          >
          <div class="br"><span>or</span></div>
          <el-button type="info" class="dialog-bd-btn" @click="updateSol">{{
            $t("sol_body_chain_error_btn_2", { name: this.network.name })
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contract } from "../lib/connectWallet";
import { ethers } from "ethers";
import { formatDate } from "../utils/utils";
import { BigNumber } from "bignumber.js";
import EventValue from "./eventValue";
import { walletSwitchChain } from '../lib/walletSwitchChain'
export default {
  name: "SolBody",
  props: ["address", "abi", "result", "chain", "sol"],
  components: {
    EventValue,
  },
  data() {
    return {
      value1: '',
      visible: false,
      running: false,
      activeNames: [],
      contract: null,
      jsons: [],
      otherName: "",
      fun: {},
      formData: {},
      conversionType: "",
      decimals: 18,
      decimalsInput: "",
      isShowDecimalsInput: false,
      isShowConversion: false,
      loading: false,
      isShowDialog: false,
      showFunSet: false,
      showSendSet: false,
      conversionOptions: [
        {
          label: "toWei(10^18)",
          value: 18,
        },
        {
          label: "toGwei(10^9)",
          value: 9,
        },
        {
          label: this.$t("diy"),
          value: "",
        },
      ],
    };
  },
  computed: {
    web3() {
      let web3 = this.$store.state.web3;
      return web3;
    },
    abiList() {
      let abi = this.abi || [];
      let list = abi.filter((e) => e.type == "function");
      let readAbi = list.filter((e) => e.stateMutability == "view");
      let writeAbi = list.filter((e) => e.stateMutability != "view");
      return { readAbi, writeAbi };
    },
    walletAddress() {
      let address = this.$store.state.address;
      return address;
    },
    network() {
      let network = this.$store.state.network;
      return network;
    },
    chains() {
      let chains = this.$store.state.chains;
      return chains;
    },
  },
  watch: {
    address() {
      if (this.web3) {
        this.contract = contract(this.address, this.abi, this.web3.getSigner());
      }
    },
    abi() {
      if (this.web3) {
        this.contract = contract(this.address, this.abi, this.web3.getSigner());
      }
    },
    web3(val) {
      this.contract = contract(this.address, this.abi, val.getSigner());
    },
    network() {
      if (this.running) {
        this.apply()
        this.running = false
      }
    }
  },
  async mounted() {
    if (this.web3) {
      this.contract = contract(this.address, this.abi, this.web3.getSigner());
    }
  },
  filters: {
    slimAddress(val = "") {
      return `${val.slice(0, 6)}......${val.slice(-6)}`;
    },
  },
  methods: {
    replay(data) {
      console.log(data);
      this.formData = JSON.parse(JSON.stringify(data.formData));
      this.fun = data.funData;
      if (data.funStateMutability == "payable") {
        this.formData["payableValue"] = formData.value;
        delete this.formData["value"];
      }
      // this.apply()
    },
    getParameter(e) {
      let r = e.match(/\((.+)\)/);
      if (r) {
        return r[1].split(",");
      } else {
        return [];
      }
    },
    isBooleanType(val) {
      return typeof val === "boolean";
    },
    changeChain() {
      this.isShowDialog = false;
      // this.$emit("changeChain");
      console.log(this.chain.chainId)
      walletSwitchChain(this.chain.chainId).then(() => {
        this.$store.commit("getWeb3")
        this.$notify.success({
          title: this.$t('notify_status_success'),
          message: this.$t('notify_changechain_success_msg', {chain: this.chain.chainName}),
        })
        this.running = true
      }).catch(err => {
        this.$notify.error({
          title: this.$t('notify_status_error'),
          message: err,
        })
      })
    },
    updateSol() {
      let sol = this.sol;
      console.log(this.chains);
      let chainData = this.chains.filter(
        (e) => e.chainId == this.network.chainId
      )[0];
      let chain = {
        chainId: chainData.chainId,
        chainName: chainData.name,
        coinName: chainData.chain,
        rpc: chainData.rpc,
      };
      console.log(chain);
      sol.chain = chain;
      this.$emit("updateSol", sol);
      this.isShowDialog = false;
      setTimeout(() => this.apply(), 500);
    },
    toEtherscan(hash) {
      let network = this.network;
      let name = "";
      if (network.name.toLowerCase() == "ropsten") {
        name = "ropsten.";
      } else if (network.name.toLowerCase() == "kovan") {
        name = "kovan.";
      } else if (network.name.toLowerCase() == "rinkeby") {
        name = "rinkeby.";
      } else if (network.chainId == 5) {
        name = "goerli.";
      } else if (network.name.toLowerCase() == "sepolia") {
        name = "sepolia.";
      }
      let url = `https://${name}etherscan.io/tx/${hash}`;
      window.open(url);
    },
    copy(value, type) {
      if (type == "address") {
        value = value.replace(/\"/g, "");
      } else {
        value = JSON.stringify(value);
      }
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value);
        this.$notify.success({
          title: this.$t("notify_status_success"),
          message: this.$t("copy_success_msg"),
        });
      }
    },
    toWei() {
      if (!this.decimals && !this.decimalsInput) {
        this.$message({
          message: this.$t("to_wei_error_msg"),
          type: "warning",
        });
        return;
      }
      let type = this.conversionType;
      let formData = this.formData;
      let decimals = this.decimals || this.decimalsInput;
      let input = ethers.utils
        .parseUnits(formData[type].toString(), decimals)
        .toString();
      this.formData[type] = input;
      this.decimals = 18;
      this.decimalsInput = "";
      this.conversionType = "";
      (this.isShowDecimalsInput = false), (this.isShowConversion = false);
    },
    conversion(type) {
      console.log(type);
      if (!this.formData[type]) {
        this.$message({
          message: this.$t("conversion_error_msg"),
          type: "warning",
        });
        return;
      }
      this.conversionType = type;
      this.isShowConversion = true;
    },
    selectChange(e) {
      if (!e) {
        this.isShowDecimalsInput = true;
      } else {
        this.isShowDecimalsInput = false;
        this.decimalsInput = "";
      }
    },
    isUint(type) {
      return type.indexOf("uint") > -1;
    },
    showConversion(type, value) {
      console.log(type, value);
      return type.indexOf("uint") > -1 && value && Math.floor(value) != value;
    },
    isTextarea(type) {
      return type.indexOf("[]") > -1;
    },
    async apply() {
      if (!this.web3 || !(this.network && this.network.chainId)) {
        this.$store.commit("getWeb3");
        return;
      }
      if (this.network.chainId != this.chain.chainId) {
        console.log(this.network.chainId, this.chain, this.chain.chainId);
        this.isShowDialog = true;
        return;
      }
      this.loading = true;
      let sendInfo = { from: this.walletAddress };
      if (this.formData["payableValue"]) {
        if (
          Math.floor(this.formData["payableValue"]) !=
          this.formData["payableValue"]
        ) {
          this.conversion("payableValue");
          this.loading = false;
          return;
        }
        sendInfo["value"] = this.formData["payableValue"];
      }
      if (this.formData["gasPrice"]) {
        if (
          Math.floor(this.formData["gasPrice"]) != this.formData["gasPrice"]
        ) {
          console.log(1);
          this.conversion("gasPrice");
          this.loading = false;
          return;
        }
        sendInfo["gasPrice"] = ethers.utils.parseUnits(
          this.formData["gasPrice"],
          "gwei"
        );
      }
      try {
        delete this.formData["payableValue"];
        delete this.formData["gasPrice"];
      } catch (error) {}

      let formData = JSON.parse(JSON.stringify(this.formData));
      let inputs = this.fun.inputs;
      let params = {};
      inputs.forEach((e) => {
        if (e.type.indexOf("[]") > -1) {
          let value = formData[e.name]
            ? formData[e.name]
                .replace(/\"/g, "")
                .replace(/\'/g, "")
                .replace(/\s+/g, ",")
                .replace(/\[|]/g, "")
                .replace(/(\r\n)|(\n)/g, ",")
            : "";
          value = value.split(",");
          value = value.filter((e) => e && e.trim());
          value = value.map((e) => ethers.utils.hexlify(e));
          formData[e.name] = value;
        }
        params[e.name] = formData[e.name];
      });
      let result;
      let resultState;
      console.log(Object.values(params), sendInfo, this.contract, this.fun);
      try {
        let contractFun = null;
        if (this.contract[this.fun.name]) {
          contractFun = this.contract[this.fun.name];
        } else {
          contractFun =
            this.contract[
              this.fun.name +
                `(${this.fun.inputs.map((e) => e.internalType).join()})`
            ];
        }
        result = await contractFun(...Object.values(params), sendInfo);
        console.log(result);
        if (BigNumber.isBigNumber(result)) {
          console.log(1);
          // result = result.toNumber()
          // result = ethers.BigNumber.from(result).toString()

          result = ethers.utils.formatUnits(result, 0);
        }
        resultState = "success";
        this.$notify.success({
          title: this.$t("notify_status_success"),
          message: this.$t("sol_body_result_success"),
        });
      } catch (error) {
        console.log(error);
        result = error;
        resultState = "error";
        this.$notify.error({
          title: this.$t("notify_status_error"),
          message: this.$t("sol_body_result_fail"),
        });
      }
      console.log(this.fun);
      let fun = `Function: ${this.fun.name}(`;
      for (let i = 0; i < this.fun.inputs.length; i++) {
        if (i > 0) fun += ", ";
        fun += this.fun.inputs[i].internalType + " " + this.fun.inputs[i].name;
      }
      fun += ")";
      let sendFormData = JSON.parse(JSON.stringify(sendInfo));
      delete sendFormData["from"];
      let resultData = {
        content: result,
        state: resultState,
        formData: Object.assign(formData, sendFormData),
        fun,
        funData: this.fun,
        name: this.fun.name,
        createAt: new Date(),
      };
      this.loading = false;
      this.fun = {};
      this.formData = {};
      this.$emit("resulted", resultData);
      if (result && result.hash) {
        let tx = result;
        const receipt = await tx.wait();
        resultData.content = Object.assign(resultData.content, receipt);
        resultData.confirmed = Math.ceil(
          (new Date().getTime() - resultData.createAt.getTime()) / 1000
        );
        this.$emit("resulted", resultData);
      }
    },

    saveOtherName() {
      let abi = this.abi;
      let sol = this.sol;
      let fun = this.fun;
      for (let i = 0; i < abi.length; i++) {
        if (abi[i].name == fun.name) {
          abi[i].otherName = this.otherName;
        }
      }
      sol.abi = abi;
      console.log(sol);
      this.$emit("updateSol", sol);
    },
    selectMenu(e) {
      this.fun = e;
      this.showSendSet = false;
      if (this.fun.stateMutability == "payable") this.showSendSet = true;
      this.otherName = e.otherName || "";
      this.formData = {};
    },
    delFun() {
      this.fun = {};
    },
    formatDate(time) {
      return formatDate("YYYY-mm-dd HH:MM:SS", time);
    },
    pickerChange(e,name) {
      let time = Math.floor(new Date(e).getTime() / 1000)
      this.formData[name] = time
      console.log(this.formData)
      this.visible = false
    },
    isJson(str) {
      console.log(str);
      try {
        if (typeof str == "object") {
          return true;
        }
      } catch (e) {}
      return false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sol-body {
  display: flex;
  align-items: top;
  color: #2f343d;
  .fun-name {
    color: #909399;
    margin-top: 6px;
    font-size: 12px;
  }
  .right {
    flex: 1;
    margin-left: 15px;
    box-sizing: border-box;
    .right-section-wrap {
      border: solid 1px #e6e6e6;
      border-radius: 5px;
      padding: 12px;
      .fun-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
    .right-section {
      position: relative;
      border: 1px dashed #e6e6e6;
      border-radius: 5px;
      width: 100%;
      padding: 20px 12px;
      box-sizing: border-box;
      margin-top: 20px;
      .section-title {
        position: absolute;
        top: -0.5em;
        left: 6px;
        font-size: 14px;
        line-height: 1;
        padding: 0 6px;
        background-color: #fff;
        color: #606266;
        transition: all 0.3s;
        span {
          margin-left: 10px;
          font-size: 12px;
        }
      }
      .section-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        transition: all 0.3s;
      }
      .section-form {
        & ~ .section-form {
          margin-top: 20px;
        }
        .form-label {
          width: 55%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > div {
            display: flex;
            align-items: center;
            span {
              color: #909399;
              margin-left: 8px;
              font-size: 12px;
            }
          }
          .sort {
            font-size: 12px;
            color: #909399;
            cursor: pointer;
            display: flex;
            align-items: center;
            i {
              font-size: 14px;
              transform: rotate(90deg);
              margin-left: 2px;
            }
          }
        }
        .form-input {
          margin-top: 16px;
          display: flex;
          .section-btn {
            margin-left: 10px;
            border-radius: 5px;
          }
        }
      }
      .show-arrow {
        position: absolute;
        top: 0;
        height: 42px;
        display: flex;
        align-items: center;
        font-size: 20px;
        right: 12px;
        cursor: pointer;
      }
    }
    .section-send-info {
      // height:0;
      // opacity:0;
      overflow: hidden;
      transition: all 0.3s;
    }
    .result {
      padding: 16px 24px;
      & ~ .result {
        margin-top: 10px;
      }
      .result-hd {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .result-title {
          display: flex;
          align-items: center;
          // span {
          //   font-size: 12px;
          //   color: #606266;
          //   margin-left: 10px;
          // }
          i {
            font-size: 20px;
            margin-left: 20px;
          }
        }
        .result-hash {
          display: flex;
          align-items: center;
          svg {
            margin-left: 12px;
            cursor: pointer;
            stroke: #606266;
            & ~ svg {
              margin-left: 6px;
            }
          }
        }
      }
      .result-msg {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .msg-item {
          margin-right: 14px;
          border-radius: 5px;
          border: 2px solid #f5f5f5;
          font-size: 12px;
          height: 24px;
          display: flex;
          align-items: center;
          background: #f5f5f5;
          span {
            background: #f5f5f5;
            padding: 0 12px;
            height: 100%;
            color: #000;
            display: flex;
            align-items: center;
            & ~ span {
              color: #000;
              background: #ffffff;
              width: 70px;
              text-align: center;
              justify-content: center;
              border-radius: 5px;
            }
          }
        }
      }
      .result-time {
        display: flex;
        margin-top: 16px;
        .time-item {
          margin-right: 60px;
          font-size: 14px;
        }
      }
      .result-parameter {
        margin-top: 20px;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .result-function {
        background: #f5f5f5;
        font-size: 14px;
        color: #42b983;
        padding: 20px;
        box-sizing: border-box;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: relative;
        z-index: 9;
        word-break: break-word;
        white-space: normal;
      }
      .result-content {
        margin-top: 10px;
        border-radius: 5px;
        // border: 1px solid #e6e6e6;
        box-sizing: border-box;
        padding: 12px 0;
        .result-text {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-bottom: 10px;
          svg {
            fill: #67c23a;
            width: 16px;
            height: 16px;
            margin-right: 6px;
            &.icon-error {
              fill: #f56c6c;
            }
          }
        }
        p {
          margin-top: 16px;
          font-size: 16px;
          margin-left: 2px;
        }
        .event-item {
          background: #f5f5f5;
          border-radius: 5px;
          padding: 20px;
          box-sizing: border-box;
          margin-bottom: 14px;
          font-size: 14px;
          .event-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .show-arrow {
              font-size: 20px;
              cursor: pointer;
            }
          }
          .parameter {
            margin-top: 20px;
          }
          .parameter-item {
            display: flex;
            margin-top: 8px;
            font-size: 12px;
            align-items: center;
            span {
              padding: 4px 14px;
              height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              background: #42b983;
              border-radius: 20px;
              width: 80px;
            }
            .event-value {
              margin-left: 20px;
              background: none;
              line-height: 26px;
              p {
                padding: 0;
                margin: 0;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
style {
  .el-card__body {
    box-sizing: border-box;
  }
}
.conversion-dialog {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  .conversion-content {
    padding: 18px;
    border-radius: 5px;
    background: #ffffff;
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 12px;
    .dialog-hd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      i {
        font-size: 18px;
        cursor: pointer;
      }
    }
    .dialog-bd {
      margin-top: 20px;
      .hint {
        font-size: 12px;
        color: #909399;
        margin-top: 6px;
      }
    }
    .btn {
      width: 100%;
      margin-top: 20px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #3997ee;
      border-radius: 5px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog-content {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    width: 550px;
    .dialog-hd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dialog-title {
        font-size: 16px;
        box-sizing: border-box;
        font-weight: 500;
      }
      .dialog-close {
        cursor: pointer;
      }
    }
    .dialog-bd {
      box-sizing: border-box;
      margin-top: 16px;
      border-radius: 10px;
      .dialog-bd-content {
        color: black;
        font-weight: 400;
        font-size: 14px;
        box-sizing: border-box;
        margin-bottom: 6px;
      }
      .dialog-bd-btn {
        width: 100%;
        height: 36px;
        padding: 0;
        line-height: 36px;
        margin-top: 14px;
        border-radius: 5px;
      }
      .br {
        font-size: 14px;
        text-align: center;
        margin-top: 14px;
        line-height: 1;
        position: relative;
        span {
          position: relative;
          z-index: 2;
          padding: 0 10px;
          background: #ffffff;
        }
        &::after {
          content: "";
          height: 1px;
          width: 100%;
          background: #e6e6e6;
          position: absolute;
          top: 49.9%;
          left: 0;
        }
      }
    }
  }
}
.fun-other-name {
  white-space: nowrap;
}

.conversion-list {
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-size: 14px;
  .conversion-item {
    padding: 4px 12px;
    box-sizing: border-box;
    background: #000;
    color: #ffffff;
    border-radius: 5px;
    font-size: 12px;
    margin-right: 14px;
    cursor: pointer;
  }
  .conversion-hint {
    color: #f56c6c;
  }
}
</style>
<style lang="scss">
.sol-body {
  .el-menu-vertical {
    min-height: 400px;
    border-radius: 5px;
    border: solid 1px #e6e6e6;
  }
  .el-menu {
    width: 200px;
    // border: none;
    border-radius: 5px;
    .el-submenu {
      .el-menu-item {
        font-size: 14px;
        width: 200px;
        min-width: 200px;
        box-sizing: border-box;
        padding: 10px 0 !important;
        padding-left: 24px !important;
        height: auto;
        line-height: 1;
      }
    }
    .el-submenu__title {
      font-size: 14px;
      height: 36px;
      line-height: 36px;
    }
  }
  .el-input__inner {
    border-radius: 5px;
    height: 36px;
    line-height: 36px;
    outline: none;
  }
  .el-input__inner:focus {
    border: 1px solid #dcdfe6;
  }
  .jv-container {
    .jv-code {
      background: #f5f5f5;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 5px;
      // margin-top: 18px;
    }
  }
  .jv-node {
    background: #f5f5f5;
    padding: 2px;
  }
}
</style>
