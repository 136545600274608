import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import JsonViewer from 'vue-json-viewer'
import Vuex from 'vuex'
import store from '@/store/store.js'
import rightMenu from "vue-right-click-menu";
import { Notification } from 'element-ui';
import './assets/css/reset.scss'
import { messages } from './lib/I18n'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
Vue.use(Vuex)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(JsonViewer);
Vue.use(rightMenu);
const i18n = new VueI18n({
  local: 'en',
  messages
})
i18n.locale = 'en'
if (!localStorage.getItem('notificationInit')) {
  Notification({
    dangerouslyUseHTMLString: true,
    message: `"I think I speak very poor English，but anyway I dare to say，I think it's very important。"<br />If you can help with translation, please contact us 👉 <a href="mailto:lee-perfect@qq.com" style="color:#409EFF">lee-perfect@qq.com</a>`
  })
  localStorage.setItem('notificationInit', true)
}
new Vue({
  i18n,
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
