<template>
  <el-header class="header" style="padding: 0 24px">
    <div class="logo" >
      <img src="@/assets/images/logo.png" alt="">
    </div>
    <div class="header-r">
      <div style="display: flex;align-items: center;position: relative;">
        <svg t="1660392975370" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2493" width="18" height="18"><path d="M929.1 637.6c-16.1-36-10.4-73.2-4.9-109.1 8.5-55.6 18.8-123.7-69.7-163.5 5.9-21.2 5.4-45.6-12.5-54.2l-105.3-50.7c-23-11.1-83-8.7-90.1 5.9-7 14.6-26.4 45 22.8 56.3s95 68.9 95 68.9c16.5 16.1 35.5 25.7 50.4 25.9 59 20.7 55.7 44.9 47 101.8-6.3 40.7-14 91.5 9.7 144.5 32 71.4 33.9 113.4 6.3 140.4-33.8 33.3-79.5 22.7-108.3 3.6-38.9-25.8-39.5-73.5-36-149.1 2-42.8 4.1-87-8.5-124.7C696.9 450 611 449 607 449h-38.2V164.9c0-17.4-14.2-31.6-31.6-31.6H158.7c-17.3 0-31.6 14.2-31.6 31.6v662.6H95.6s-31.6 0-31.6 31.6v31.6h568.2v-31.6s0-31.6-31.6-31.6H569V512h37.9c4.6 0.1 45.2 2 58.5 41.6 8.9 26.5 7.1 63 5.3 101.7-3.5 73.7-7.4 157.2 64.1 204.6 28.7 19 60 28.4 90.3 28.4 35.9 0 70.4-13.3 97.1-39.5 70.4-69.2 24.2-172.3 6.9-211.2zM785.4 376c-8.7 0-15.8-7.1-15.8-15.8 0-8.7 7.1-15.8 15.8-15.8s15.8 7.1 15.8 15.8-7 15.8-15.8 15.8z m-279.7 41.3c0 17.3-14.2 31.6-31.6 31.6H221.8c-17.3 0-31.6-14.2-31.6-31.6V228c0-17.3 14.2-31.6 31.6-31.6h252.4c17.3 0 31.6 14.2 31.6 31.6v189.3h-0.1z" p-id="2494" fill="#2c2c2c"></path></svg>
        <span style="margin-left: 4px;margin-right:20px">{{gasPrice}}</span>
        <div class="progress">
          <el-progress :percentage="20 * progress" :show-text="false"></el-progress>
        </div>
      </div>
      <div class="chain">
        <el-select
          v-if="network"
          style="width: 100%"
          v-model="network.chainId"
          filterable
          allow-create
          default-first-option
          @change="handleChange"
          :placeholder="$t('nav_chain_select_placeholder')">
          <el-option
            v-for="item in chains"
            :key="item.name"
            :label="item.name"
            :value="item.chainId">
          </el-option>
        </el-select>
      </div>
      <div class="user-info">
        <div class="balance">{{balance}} {{coinName}}</div>
        <div class="address">
          <span @click="copy">{{address}}</span>
          <Jazzicon 
            v-if="address"
            class="jazzicon"
            :diameter="16" 
            :address="fullAddress"
          />
        </div>
      </div>
      <div class="setting">
        <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="Menu__StyledMenuIcon-sc-1s7r6xc-0 eQiQbQ"><path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> -->
        <div class="setting-btn" @click="changeLocale">{{$i18n.locale == 'en' ? '中文' : 'Engilsh'}}</div>
      </div>
    </div>
    <div class="dialog" v-if="isShowDialog">
      <div class="dialog-content">
        <div class="dialog-hd">
          <div class="dialog-title">{{$t('nav_user_title')}}</div>
          <div class="dialog-close" @click="isShowDialog=false">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="AccountDetails__CloseColor-sc-75okty-10 loWQUb"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </div>
        </div>
        <div class="dialog-bd">
          <div class="user-info">
            <div class="address">
              <Jazzicon 
                v-if="address"
                class="jazzicon"
                :diameter="16" 
                :address="fullAddress"
              />
              <span >{{address}}</span>
            </div>
          </div>
          <div class="operation">
            <div class="copy" @click="copy">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
              <span>{{$t('nav_user_copy')}}</span>
            </div>
            <div>
              <a :href="'https://rinkeby.etherscan.io/address/'+fullAddress" class="to-etherscan" target="_blank" >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                <span>{{$t('nav_user_etherscan')}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-header>
</template>

<script>
import Jazzicon from '../components/Jazzicon'
import {walletSwitchChain} from '../lib/walletSwitchChain'
import { ethers } from 'ethers'
let timeout
export default {
  name: 'Header',
  components: {
    Jazzicon: Jazzicon
  },
  computed: {
    chains() {
      return this.$store.state.chains
    },
    balance() {
      let balance = +this.$store.state.balance
      return balance.toFixed(2)
    },
    fullAddress() {
      return this.$store.state.address
    },
    address() {
      let address = this.$store.state.address
      return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ''
    },
    network() {
      return this.$store.state.network
    },
    web3() {
      let web3 = this.$store.state.web3
      return web3
    }
  },
  data() {
    return {
      gasPrice: '',
      coinName: '',
      chainId: '',
      isShowDialog: false,
      progress: 0
    }
  },
  watch: {
    web3() {
      this.getGasPrice()
    },
    network(val) {
      console.log(val)
      let network = val
      let chains = this.$store.state.chains
      let chain = chains.filter(e => e.chainId == network.chainId)[0]
      let coinName = chain && chain.chain || ''
      if (coinName == 'ETH') {
        if (chain.network && chain.network == 'testnet') {
          this.coinName = `${chain.name[0].toLocaleLowerCase()}ETH`
        } else {
          this.coinName = `ETH`
        }
      } else {
        this.coinName = coinName
      }
    },
    '$i18n.locale': { 
      handler(val) { 
        if (val == 'en') {
          this.$notify({
            dangerouslyUseHTMLString: true,
            message: `"I think I speak very poor English，but anyway I dare to say，I think it's very important。"<br />If you can help with translation, please contact us 👉 <a href="mailto:lee-perfect@qq.com" style="color:#409EFF">lee-perfect@qq.com</a>`
          });
        }
      },
      // immediate: true 
    }
  },
  async mounted() {
    if (this.web3) {
      clearTimeout(timeout)
      this.progress = 0
      timeout = setTimeout(() => {this.getProgress()}, 2000)
    }
  },
  beforeDestroy() {
    clearTimeout(timeout)
  },
  methods: {
    changeLocale() {
      if (this.$i18n.locale == 'en') {
        this.$i18n.locale = 'zh'
      } else {
        this.$i18n.locale = 'en'
      }
    },
    async getGasPrice() {
      clearTimeout(timeout)
      let gasPrice = await this.web3.getGasPrice()
      this.gasPrice = Math.floor(ethers.utils.formatUnits(gasPrice, "gwei"))
      this.progress = 0
      console.log(this.gasPrice)
      timeout = setTimeout(() => {this.getProgress()}, 2000)
    },
    getProgress() {
      if (this.progress == 5) {
        this.getGasPrice()
        return
      } else {
        this.progress += 1
      }
      timeout = setTimeout(() => {this.getProgress()}, 1000)
    },
    handleChange(e) {
      this.chainId = e
      walletSwitchChain(e).then(() => {
        this.$store.commit("getWeb3")
      })
    },
    copy() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.fullAddress)
        this.$notify.success({
          title: this.$t('copy_status_success'),
          message: this.$t('copy_success_msg'),
        });
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: rgba(0, 0 ,0, .08) 0px 0px 5px;
  position: relative;
  z-index: 99;
  .logo {
    img {
      width: 140px;
      height: auto;
    }
  }
  .header-r {
    display: flex;
    align-items: center;
    .user-info {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 2px;
      box-sizing: border-box;
      box-shadow: rgba(0, 0 ,0, .08) 0px 0px 5px;
      border-radius: 20px;
      height: 40px;
      margin-left: 20px;
      margin-right: 20px;
      .balance {
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: 8px;
      }
      .address {
        font-size: 0;
        display: flex;
        align-items: center;
        height: 40px;
        background-color: #F7F8FA;
        border-radius: 20px;
        padding: 0 8px;
        box-sizing: border-box;
        height: 36px;
        span {
          font-size: 14px;
          height: 36px;
          line-height: 36px;
          margin-right: 6px;
          cursor: pointer;
        }
        .jazzicon {
          height: 36px;
          display: flex;
          align-items: center;
        }
      }
    }
    .progress {
      position: absolute;
      bottom: -10px;
      left: -10px;
      right: 0;
      width: 100%;
    }
    .setting {
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 40px;
      border-radius: 5px;
      .setting-btn {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        box-shadow: rgba(0, 0 ,0, .08) 0px 0px 3px;
        cursor: pointer;
      }
    }
  }
  .dialog {
    .user-info {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 2px;
      box-sizing: border-box;
      border-radius: 20px;
      height: 40px;
      margin-left: 20px;
      margin-right: 20px;
      .address {
        font-size: 0;
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 20px;
        padding: 0 8px;
        box-sizing: border-box;
        height: 36px;
        span {
          font-size: 14px;
          height: 36px;
          line-height: 36px;
          margin-right: 6px;
          cursor: pointer;
        }
        .jazzicon {
          height: 36px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog-content {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    width: 400px;
    .dialog-hd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dialog-title {
        font-size: 16px;
        box-sizing: border-box;
        font-weight: 500;
      }
      .dialog-close {
        cursor: pointer;
      }
    }
    .dialog-bd {
      border: 1px solid rgb(206, 208, 217);
      box-sizing: border-box;
      margin-top: 16px;
      padding: 16px;
      border-radius: 10px;
    }
    .user-info {
      margin: 0;
      padding: 0;
      .address {
        padding: 0;
        .jazzicon {
          margin-right: 8px;
        }
        span {
          color: rgb(0, 0, 0);
          font-weight: 500;
          font-size: 18px;
          box-sizing: border-box;
        }
      }
    }
    .operation {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .to-etherscan {
        margin-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .copy {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      span {
        font-weight: 500;
        color: rgb(110, 114, 125);
        font-size: 12px;
        box-sizing: border-box;
        margin-left: 4px;
      }
    }
  }
}
</style>
<style lang="scss">
  .ant-select-dropdown {
    width: 300px !important;
  }
  .el-progress-bar__inner {
    transition: width 1s linear !important;
  }
</style>
