export const messages = {
  zh: {
    'btn_etherscan': 'Etherscan 查看',
    'btn_share': '分享合约',
    'btn_del': '删除合约',
    'btn_edit': '编辑合约',
    'btn_change': '跳转当前链',
    'desc_time': '创建时间',
    'desc_chain': '链条',
    'desc_balance': '合约余额',
    'desc_address': '合约地址',
    'desc_abi': 'ABI',
    'desc_copy': '点击复制ABI',
    'desc_remark': '备注',
    'desc_msg': '该合约未选择网络',
    'notify_status_success': '成功',
    'notify_status_error': '错误',
    'notify_status_info': '提示',
    'notify_changechain_success_msg': '已成功切换到对应链条',
    'notify_changechain_info_msg': '已处于当前链条',
    'copy_status_success': '成功',
    'copy_success_msg': '已成功复制到剪切板',
    'notify_edit_error_msg': '请先选择合约',
    'add_collection_title': '添加文件夹',
    'add_collection_form_name': '文件夹名称',
    'add_collection_form_name_msg': '请输入文件夹名称',
    'cancel': '取消',
    'ok': '确定',
    'nav_chain_select_placeholder': '请选择链条',
    'nav_user_title': '账户',
    'nav_user_copy': '复制地址',
    'nav_user_etherscan': '在以太坊资源浏览器上查看',
    'add_contract_title': '创建合约模型',
    'add_contract_name': '项目名称',
    'add_contract_name_msg': '请输入项目名称',
    'add_contract_address': '项目地址',
    'add_contract_address_msg': '请输入项目地址',
    'add_contract_chain': '合约所在链',
    'add_contract_chain_msg': '请选择网络',
    'add_contract_chain_placeholder': '请选择',
    'add_contract_abi': '导入ABI',
    'add_contract_abi_msg': '请输入ABI',
    'add_contract_abi_update': '上传ABI文件',
    'add_contract_abi_copy': '粘贴ABI文件',
    'add_contract_abi_etherscan': 'etherscan导入',
    'add_contract_abi_copy': '粘贴ABI文件',
    'add_chain_title': '添加自定义链',
    'add_chain_name': '链名称',
    'add_chain_name_placeholder': '例：Ethereum',
    'add_chain_id_placeholder': '例：1',
    'add_chain_coin': '主币名称',
    'add_chain_coin_placeholder': '例：ETH',
    'import_api_title': '输入API密钥',
    'import_api_label': 'API密钥',
    'import_api_hint_1': '使用etherscan导入功能需要提供etherscan的api kay(本地存储), 如果你没有申请api kay, 可以',
    'import_api_hint_2': '点击"链接"申请',
    'diy': '自定义',
    'import_api_error_msg': '请先添加合约地址',
    'import_api_error_msg_chain': '请先添加合约网络',
    'import_api_error_not_verified': '当前合约未开源,无法通过etherscan获取',
    'del_chain_hint_title': '提示',
    'del_chain_hint_content': '此操作将永久删除该链条信息, 是否继续?',
    'submit_model_abi_error': 'json 格式校验错误',
    'add_chain_error_msg': '该链条已存在',
    'share_title': '合约“{name}”分享状态',
    'share_authority': '权限选择',
    'share_authority_placeholder': '请权限选择',
    'share_private_msg': '只有自己可以查看当前合约',
    'share_global_msg': '所有人都可以查看当前合约',
    'share_password_msg': '所有拥有密码的人可以查看当前合约',
    'share_password_input_title': '输入密码',
    'share_password_input_placeholder': '请输入密码',
    'share_btn1': '同步',
    'share_btn2': '分享',
    'share_success_title': '分享成功',
    'share_success_sub_title': '分享你的合约链接',
    'share_copy': '复制链接',
    'get_sol_title': '同步合约',
    'right_menu_del': '删除合约',
    'right_menu_top': '置顶',
    'right_menu_add': '添加合约',
    'right_menu_del_coll': '删除文件夹',
    'del_sol_hint_msg': '此操作将永久删除该合约信息, 是否继续?',
    'hint_title': '提示',
    'get_sol_error_msg': '该合约已存在',
    'del_collection_msg': '此操作将永久删除文件夹以及内部合约, 是否继续?',
    'sol_body_read': '读函数',
    'sol_body_write': '写函数',
    'sol_body_set_remark': '设置备注',
    'sol_body_set_remark_item': '函数别名',
    'sol_body_set_remark_save': '保存',
    'sol_body_section_title_1': '参数',
    'sol_body_section_type': '数据类型',
    'sol_body_util_1': '数字转换',
    'sol_body_util_2': '选择日期时间',
    'sol_body_util_3': '插入时间',
    'sol_body_textarea_placeholder': '可用换行或逗号隔开',
    'sol_body_other_value': '附带参数',
    'sol_body_other_value_msg': '执行合约时附带ETH数量',
    'apply': '执行',
    'sol_body_result_title': '运行函数',
    'sol_body_result_hash': '交易哈希',
    'sol_body_result_params': '数据输入',
    'sol_body_result_success': '执行成功',
    'sol_body_result_fail': '执行失败',
    'sol_body_result_events': '事件列表',
    'sol_body_result_empty': '请选择左侧函数并执行',
    'sol_body_decimals_placeholder': '请选择',
    'sol_body_decimals_input_placeholder': '请输入内容',
    'sol_body_decimals_input_hint': '请输入添加多少0',
    'sol_body_decimals_btn': '转换',
    'sol_body_chain_error': '当前链错误',
    'sol_body_chain_error_content': '当前钱包连接的链和本合约所在链不同，请切换链',
    'sol_body_chain_error_btn_1': '切换到{name}链并运行函数',
    'sol_body_chain_error_btn_2': '将当前合约链更改为{name}并运行函数',
    'to_wei_error_msg': '请先输入位数',
    'conversion_error_msg': '请先输入内容',
    'import': '导入'
  },
  en: {
    'import': 'Imported',
    'btn_etherscan': 'View in Etherscan',
    'btn_share': 'Share Contract',
    'btn_del': 'Delete Contract',
    'btn_edit': 'Edit Contract',
    'btn_change': 'Current Chain',
    'desc_time': 'Created Time',
    'desc_chain': 'Chain',
    'desc_balance': 'Balance',
    'desc_address': 'Contract Address',
    'desc_abi': 'ABI',
    'desc_copy': 'Click to copy ABI',
    'desc_remark': 'Remark',
    'desc_msg': 'Please select a chain',
    'notify_status_success': 'Success',
    'notify_status_error': 'Error',
    'notify_status_info': 'Info',
    'notify_changechain_success_msg': 'Successfully switched to {chain}',
    'notify_changechain_info_msg': 'Already in current chain',
    'copy_status_success': 'Success',
    'copy_success_msg': 'Copy successful',
    'notify_edit_error_msg': 'Please select a contract',
    'add_collection_title': 'New Folder',
    'add_collection_form_name': 'Folder Name',
    'add_collection_form_name_msg': 'Please input the folder name',
    'cancel': 'Cancel',
    'ok': 'OK',
    'nav_chain_select_placeholder': 'Selecte correct chain',
    'nav_user_title': 'Account',
    'nav_user_copy': 'Copy Address',
    'nav_user_etherscan': 'View on etherscan',
    'add_contract_title': 'Import Contract',
    'add_contract_name': 'Contract Name',
    'add_contract_name_msg': 'Please input the contract name',
    'add_contract_address': 'Contract Address',
    'add_contract_address_msg': 'Please input the contract address',
    'add_contract_chain': 'Contract Chain',
    'add_contract_chain_msg': 'Please select the chain',
    'add_contract_chain_placeholder': 'Please select',
    'add_contract_abi': 'Import ABI',
    'add_contract_abi_msg': 'Input ABI',
    'add_contract_abi_update': 'Upload an ABI file',
    'add_contract_abi_copy': 'Copy and paste ABI',
    'add_contract_abi_etherscan': 'Import from etherscan',
    'add_chain_title': 'Add custom chain info',
    'add_chain_name': 'Chain Name',
    'add_chain_name_placeholder': 'Example: Ethereum',
    'add_chain_id_placeholder': 'Example: 1',
    'add_chain_coin': 'Currency Symbol',
    'add_chain_coin_placeholder': 'Example: ETH',
    'import_api_title': 'Enter the API key',
    'import_api_label': 'API key',
    'import_api_hint_1': 'To use the etherscan services, you need to provide the etherscan api kay (we will save kay locally), if you do not have api kay, you can ',
    'import_api_hint_2': 'click the "link" to apply',
    'diy': 'Custom',
    'import_api_error_msg': 'Please input contract address',
    'import_api_error_msg_chain': 'Please input contract chain',
    'import_api_error_not_verified': 'The current contract is not open source, can not be obtained through etherscan',
    'del_chain_hint_title': 'Info',
    'del_chain_hint_content': 'This operation will permanently delete the chain information, do you want to continue?',
    'submit_model_abi_error': 'json format validation error',
    'add_chain_error_msg': 'This chain was already exists',
    'share_title': 'Share Contract',
    'share_authority': 'Permission selection',
    'share_authority_placeholder': 'Please select the contract sharing status',
    'share_private_msg': 'Only you can view the current contract',
    'share_global_msg': 'Everyone can view the current contract',
    'share_password_msg': 'Everyone with password can view the current contract',
    'share_password_input_title': 'Input Password',
    'share_password_input_placeholder': 'Password for the contract',
    'share_btn1': 'Sync',
    'share_btn2': 'Share',
    'share_success_title': 'Share success',
    'share_success_sub_title': 'Share your contract link',
    'share_copy': 'Copy the link',
    'get_sol_title': 'Sync Contract',
    'right_menu_del': 'Delete Contract',
    'right_menu_top': 'Sticky Top',
    'right_menu_add': 'Add Contract',
    'right_menu_del_coll': 'Delete Folder',
    'del_sol_hint_msg': 'This operation will permanently delete the contract information, do you want to continue?',
    'hint_title': 'Notice',
    'get_sol_error_msg': 'Contract already exists',
    'del_collection_msg': 'This operation will permanently delete the folder and the internal contract, do you want to continue?',
    'sol_body_read': 'Read Contract',
    'sol_body_write': 'Write Contract',
    'sol_body_set_remark': 'Set Notes',
    'sol_body_set_remark_item': 'Function Alias',
    'sol_body_set_remark_save': 'Save',
    'sol_body_section_title_1': 'Params',
    'sol_body_section_type': 'Data Type',
    'sol_body_util_1': 'Digital Conversion',
    'sol_body_util_2': 'Select date and time',
    'sol_body_util_3': 'Insert time',
    'sol_body_textarea_placeholder': 'Line feeds or comma separations are allowed',
    'sol_body_other_value': 'Params',
    'sol_body_other_value_msg': 'Payable Amount(ether)',
    'apply': 'Run',
    'sol_body_result_title': 'Result',
    'sol_body_result_hash': 'Hash',
    'sol_body_result_params': 'Input Params',
    'sol_body_result_success': 'Execute Success',
    'sol_body_result_fail': 'Execute Failed',
    'sol_body_result_events': 'Event List',
    'sol_body_result_empty': 'Please select the function on the left and execute',
    'sol_body_decimals_placeholder': 'Please select',
    'sol_body_decimals_input_placeholder': 'Please input',
    'sol_body_decimals_input_hint': 'Please enter the digits',
    'sol_body_decimals_btn': 'Convert',
    'sol_body_chain_error': 'Current Network Error',
    'sol_body_chain_error_content': 'The wallet is connected to a different network than the network where this contract is located, please switch networks',
    'sol_body_chain_error_btn_1': 'Switch to {name} and run the function',
    'sol_body_chain_error_btn_2': 'Change the current chain to {name} and run the function',
    'to_wei_error_msg': 'Please enter the digits first',
    'conversion_error_msg': 'Please enter the content',
  }
}